/* LandingPage.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

:root {
  /* Colors */
  --primary-color: #4361ee;
  --primary-color-rgb: 67, 97, 238;
  --primary-dark: #3a56d4;
  --secondary-color: #4cc9f0;
  --accent-color: #f72585;
  --text-primary: #1a1a2e;
  --text-secondary: #4a4a68;
  --text-tertiary: #8a8aa3;
  --bg-light: #f8f9ff;
  --bg-white: #ffffff;
  --bg-dark: #1a1a2e;
  
  /* Gradients */
  --primary-gradient: linear-gradient(135deg, #4361ee, #4cc9f0);
  --accent-gradient: linear-gradient(135deg, #f72585, #7209b7);
  
  /* Typography */
  --font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-size-base: 16px;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  
  /* Spacing */
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 16px;
  --spacing-lg: 24px;
  --spacing-xl: 32px;
  --spacing-xxl: 48px;
  
  /* Border Radius */
  --border-radius-sm: 4px;
  --border-radius-md: 8px;
  --border-radius-lg: 12px;
  --border-radius-xl: 16px;
  --border-radius-xxl: 24px;
  --border-radius-round: 50%;
  
  /* Box Shadows */
  --shadow-sm: 0 2px 8px rgba(0, 0, 0, 0.08);
  --shadow-md: 0 4px 16px rgba(0, 0, 0, 0.12);
  --shadow-lg: 0 8px 24px rgba(0, 0, 0, 0.16);
  --shadow-xl: 0 12px 32px rgba(0, 0, 0, 0.2);
}

.landing-page {
  font-family: 'Inter', sans-serif;
  max-width: 100%;
  overflow-x: hidden;
  background-color: var(--background-light);
  color: var(--text-dark);
  line-height: 1.6;
  box-sizing: border-box;
  position: relative;
  width: 100%;
}

.landing-page .logo-image {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.landing-page .section-header {
  padding: 0% 10% 2%;
  background-color: var(--background-light);
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.landing-page .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 5%;
  background-color: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  position: sticky;
  top: 0;
  z-index: 1000;
  transition: all 0.3s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  height: 80px;
}

.landing-page .header.scrolled {
  height: 70px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.landing-page .logo {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  color: var(--primary-color);
  text-decoration: none;
  gap: 12px;
}

.landing-page .logo-image {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.landing-page .nav {
  display: flex;
  align-items: center;
  gap: 32px;
}

.landing-page .nav-item {
  color: var(--text-secondary);
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  transition: all 0.2s ease;
  position: relative;
}

.landing-page .nav-item:hover {
  color: var(--primary-color);
}

.landing-page .nav-item::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--primary-color);
  transition: width 0.3s ease;
}

.landing-page .nav-item:hover::after {
  width: 100%;
}

.landing-page .sign-up-button {
  background: var(--primary-gradient);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: var(--border-radius-md);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(67, 97, 238, 0.3);
}

.landing-page .sign-up-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(67, 97, 238, 0.4);
}

.landing-page .main-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 80px 10%;
  background: linear-gradient(135deg, var(--background-light) 0%, var(--primary-light) 100%);
  min-height: calc(100vh - 80px);
  box-sizing: border-box;
}

.landing-page .left-section {
  width: 50%;
  padding-right: 40px;
  animation: fadeInRight 0.8s ease-out;
}

.landing-page .right-section { 
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeInLeft 0.8s ease-out;
}

.landing-page .tagline {
  color: var(--primary-color);
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-bottom: 24px;
  display: inline-block;
  padding: 8px 16px;
  background-color: rgba(0, 102, 255, 0.1);
  border-radius: 30px;
  animation: fadeInUp 0.6s ease-out;
}

.landing-page .main-heading {
  font-size: 3.5rem;
  line-height: 1.2;
  margin-bottom: 24px;
  font-weight: 800;
  letter-spacing: -1px;
  background: linear-gradient(90deg, #1a1a1a 0%, var(--primary-color) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  animation: fadeInUp 0.8s ease-out;
  text-align: left;
}

.landing-page .description {
  color: var(--text-medium);
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 1.8;
  animation: fadeInUp 1s ease-out;
}

.landing-page .cta-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 16px 32px;
  border-radius: var(--border-radius);
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  transition: var(--transition);
  box-shadow: 0 5px 15px rgba(0, 102, 255, 0.3);
  animation: fadeInUp 1.2s ease-out;
}

.landing-page .cta-button:hover {
  background-color: var(--primary-dark);
  transform: translateY(-3px);
  box-shadow: 0 8px 25px rgba(0, 102, 255, 0.4);
}

.landing-page .cta-button:disabled {
  background-color: var(--text-light);
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.landing-page .image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  transition: var(--transition);
  transform: perspective(1000px) rotateY(-5deg);
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: perspective(1000px) rotateY(-5deg) translateY(0px);
  }
  50% {
    transform: perspective(1000px) rotateY(-5deg) translateY(-15px);
  }
  100% {
    transform: perspective(1000px) rotateY(-5deg) translateY(0px);
  }
}

.landing-page .feature-selection {
  padding: 80px 10%;
  background-color: var(--background-light);
}

.landing-page .feature-selections-wrapper {
  background-color: var(--background-light);
  padding: 0;
}

.landing-page .feature-selection .section-header {
  text-align: center;
  padding: 0 0 40px 0;
  max-width: 800px;
  margin: 0 auto;
}

.landing-page .feature-selection .section-header h2 {
  font-size: 2.5rem;
  margin: 16px 0;
  color: var(--text-dark);
  font-weight: 700;
}

.landing-page .feature-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;
}

.landing-page .feature-list {
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.landing-page .feature-item {
  display: flex;
  align-items: center;
  padding: 24px;
  background-color: var(--background-off);
  border-radius: var(--border-radius);
  transition: var(--transition);
  cursor: pointer;
  border-left: 4px solid transparent;
  animation: fadeInRight 0.6s ease-out;
  animation-fill-mode: both;
}

.landing-page .feature-item:hover, 
.landing-page .feature-item.selected {
  background-color: var(--background-light);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
  border-left: 4px solid var(--primary-color);
  transform: translateX(5px);
}

.landing-page .feature-item:nth-child(1) { animation-delay: 0.1s; }
.landing-page .feature-item:nth-child(2) { animation-delay: 0.2s; }
.landing-page .feature-item:nth-child(3) { animation-delay: 0.3s; }
.landing-page .feature-item:nth-child(4) { animation-delay: 0.4s; }

.landing-page .feature-icon {
  font-size: 2rem;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background-color: rgba(0, 102, 255, 0.1);
  border-radius: 50%;
  flex-shrink: 0;
}

.landing-page .feature-text {
  flex: 1;
}

.landing-page .feature-text h3 {
  margin: 0 0 8px 0;
  font-size: 18px;
  font-weight: 600;
  color: var(--text-dark);
}

.landing-page .feature-text p {
  margin: 0;
  font-size: 15px;
  color: var(--text-light);
}

.landing-page .product-display {
  width: 55%;
  padding: 30px;
  background-color: var(--background-light);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  display: none;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.landing-page .product-display.visible {
  display: block;
  opacity: 1;
  transform: translateY(0);
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.landing-page .product-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.landing-page .product-header span {
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-color);
  background-color: rgba(0, 102, 255, 0.1);
  padding: 6px 12px;
  border-radius: 30px;
}

.landing-page .product-display h2 {
  font-size: 24px;
  margin: 0 0 16px 0;
  color: var(--text-dark);
}

.landing-page .product-display p {
  font-size: 16px;
  color: var(--text-medium);
  margin-bottom: 24px;
  line-height: 1.6;
}

.landing-page .product-image {
  width: 100%;
  height: auto;
  border-radius: var(--border-radius);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: var(--transition);
}

.landing-page .features-grid-section {
  padding: 80px 10%;
  background-color: var(--primary-light);
}

.landing-page .features-columns {
  display: flex;
  gap: 30px;
  margin-top: 40px;
}

.landing-page .feature-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.landing-page .column-header {
  font-size: 22px;
  font-weight: 700;
  color: var(--text-dark);
  text-align: center;
  margin-bottom: 24px;
  position: relative;
  padding-bottom: 12px;
}

.landing-page .column-header:after {
  content: '';
  position: absolute;
  width: 60px;
  height: 3px;
  background-color: var(--primary-color);
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.landing-page .benefit-item {
  background-color: var(--background-light);
  border-radius: var(--border-radius);
  padding: 24px;
  box-shadow: var(--box-shadow);
  transition: var(--transition);
  height: auto;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  animation: fadeInUp 0.8s ease-out;
  animation-fill-mode: both;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
}

.landing-page .benefit-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.landing-page .benefit-header {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 16px;
}

.landing-page .benefit-icon {
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: rgba(0, 102, 255, 0.1);
  border-radius: 50%;
  color: var(--primary-color);
  flex-shrink: 0;
}

.landing-page .benefit-header h3 {
  font-size: 18px;
  font-weight: 600;
  color: var(--text-dark);
  margin: 0;
}

.landing-page .benefit-item p {
  font-size: 15px;
  color: var(--text-medium);
  line-height: 1.6;
  margin: 0;
}

.landing-page .testimonials-section {
  padding: 80px 25px;
  background-color: var(--background-light);
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  margin: 0 auto;
}

.landing-page .testimonials-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 40px;
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
}

.landing-page .testimonial-card {
  background: var(--background-off);
  padding: 30px;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  transition: var(--transition);
  height: 100%;
  animation: fadeInUp 0.8s ease-out;
  animation-fill-mode: both;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
}

.landing-page .testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.landing-page .testimonial-card:nth-child(1) { animation-delay: 0.1s; }
.landing-page .testimonial-card:nth-child(2) { animation-delay: 0.3s; }
.landing-page .testimonial-card:nth-child(3) { animation-delay: 0.5s; }

.landing-page .testimonial-header {
  margin-bottom: 16px;
}

.landing-page .testimonial-info h3 {
  margin: 0;
  font-size: 18px;
  color: var(--text-dark);
  font-weight: 600;
}

.landing-page .testimonial-role,
.landing-page .testimonial-company {
  margin: 5px 0;
  font-size: 14px;
  color: var(--text-medium);
}

.landing-page .testimonial-rating {
  color: #ffc107;
  font-size: 20px;
  margin-bottom: 16px;
}

.landing-page .testimonial-quote {
  font-size: 16px;
  line-height: 1.6;
  color: var(--text-medium);
  font-style: italic;
  position: relative;
  padding-left: 24px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.landing-page .testimonial-quote:before {
  content: '"';
  position: absolute;
  left: 0;
  top: 0;
  font-size: 40px;
  line-height: 1;
  color: var(--primary-color);
  opacity: 0.3;
}

.landing-page .tag {
  display: inline-block;
  background-color: rgba(0, 102, 255, 0.1);
  color: var(--primary-color);
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 30px;
  margin-bottom: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.landing-page .tag-benefits {
  display: inline-block;
  background-color: var(--primary-color);
  color: white;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 30px;
  margin-bottom: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.landing-page .cta-container {
  margin-top: 40px;
  background-color: var(--background-light);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: 30px;
}

.landing-page .input-section {
  display: flex;
  gap: 30px;
}

.landing-page .left-inputs,
.landing-page .right-inputs {
  flex: 1;
}

.landing-page .email-label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-medium);
  margin-bottom: 8px;
}

.landing-page .email-input {
  width: 100%;
  padding: 14px 16px;
  border: 1px solid #e0e0e0;
  border-radius: var(--border-radius);
  font-size: 16px;
  color: var(--text-dark);
  transition: var(--transition);
  margin-bottom: 16px;
  box-sizing: border-box;
}

.landing-page .email-input:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(0, 102, 255, 0.1);
  outline: none;
}

.landing-page .email-input::placeholder {
  color: #bdbdbd;
}

.landing-page .textarea-input {
  resize: none;
  min-height: 150px;
  font-family: inherit;
}

.landing-page .name-row {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.landing-page .name-input {
  flex: 1;
}

.landing-page .submission-message {
  padding: 12px 16px;
  border-radius: var(--border-radius);
  margin-bottom: 16px;
  font-weight: 500;
}

.landing-page .submission-message.success {
  background-color: rgba(40, 167, 69, 0.1);
  color: var(--success-color);
  border: 1px solid rgba(40, 167, 69, 0.2);
}

.landing-page .submission-message.error {
  background-color: rgba(220, 53, 69, 0.1);
  color: var(--error-color);
  border: 1px solid rgba(220, 53, 69, 0.2);
}

.landing-page .footer {
  background-color: var(--bg-dark);
  color: white;
  position: relative;
}

.landing-page .footer-top {
  padding: 80px 5% 40px;
}

.landing-page .footer-content {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1.5fr;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
}

.landing-page .footer-section {
  display: flex;
  flex-direction: column;
}

.landing-page .brand-section p {
  margin: 16px 0;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.6;
}

.landing-page .footer-logo {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  color: white;
  gap: 12px;
}

.landing-page .social-links {
  display: flex;
  gap: 16px;
  margin-top: 24px;
}

.landing-page .social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  color: white;
  transition: all 0.3s ease;
}

.landing-page .social-link:hover {
  background-color: var(--primary-color);
  transform: translateY(-3px);
}

.landing-page .footer-section h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 24px;
  color: white;
}

.landing-page .footer-section a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  margin-bottom: 12px;
  transition: all 0.2s ease;
}

.landing-page .footer-section a:hover {
  color: white;
  transform: translateX(3px);
}

.landing-page .newsletter-section p {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 16px;
  line-height: 1.6;
}

.landing-page .newsletter-form {
  display: flex;
  margin-top: 8px;
}

.landing-page .newsletter-input {
  flex: 1;
  padding: 12px 16px;
  border: none;
  border-radius: var(--border-radius-md) 0 0 var(--border-radius-md);
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.landing-page .newsletter-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.landing-page .newsletter-input:focus {
  outline: none;
  background-color: rgba(255, 255, 255, 0.15);
}

.landing-page .newsletter-button {
  padding: 12px 20px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 0 var(--border-radius-md) var(--border-radius-md) 0;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.landing-page .newsletter-button:hover {
  background-color: var(--primary-dark);
}

.landing-page .footer-bottom {
  padding: 20px 5%;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.landing-page .footer-bottom-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.landing-page .footer-bottom p {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
}

.landing-page .footer-bottom-links {
  display: flex;
  gap: 24px;
}

.landing-page .footer-bottom-links a {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  font-size: 14px;
  transition: all 0.2s ease;
}

.landing-page .footer-bottom-links a:hover {
  color: white;
}

/* Responsive adjustments for footer */
@media (max-width: 992px) {
  .landing-page .footer-content {
    grid-template-columns: 1fr 1fr;
  }
  
  .landing-page .footer-top {
    padding: 60px 5% 30px;
  }
}

@media (max-width: 576px) {
  .landing-page .footer-content {
    grid-template-columns: 1fr;
    gap: 30px;
  }
  
  .landing-page .footer-bottom-content {
    flex-direction: column;
    gap: 16px;
    text-align: center;
  }
  
  .landing-page .footer-top {
    padding: 40px 5% 20px;
  }
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .landing-page .header {
    padding: 0 5%;
  }
  
  .landing-page .main-content {
    padding: 60px 5%;
  }
  
  .landing-page .feature-selection,
  .landing-page .features-grid-section {
    padding: 60px 5%;
  }
  
  .landing-page .main-heading {
    font-size: 3rem;
  }
}

@media (max-width: 992px) {
  .landing-page .main-content {
    flex-direction: column;
    padding: 40px 5%;
    min-height: auto;
  }
  
  .landing-page .left-section,
  .landing-page .right-section {
    width: 100%;
    padding-right: 0;
  }
  
  .landing-page .right-section {
    margin-top: 40px;
  }
  
  .landing-page .feature-container {
  flex-direction: column;
}

  .landing-page .feature-list,
  .landing-page .product-display {
    width: 100%;
  }
  
  .landing-page .product-display {
    margin-top: 30px;
  }
  
  .landing-page .testimonials-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .landing-page .footer-content {
    flex-wrap: wrap;
    gap: 40px;
  }
  
  .landing-page .footer-section {
    flex: 0 0 calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .landing-page .header {
    height: auto;
    padding: 16px 5%;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .landing-page .logo {
    margin-bottom: 10px;
  }
  
  .landing-page .nav {
    margin-top: 16px;
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 15px;
  }
  
  .landing-page .nav-item {
    font-size: 14px;
    margin-right: 15px;
  }
  
  .landing-page .sign-up-button {
    padding: 10px 16px;
    font-size: 14px;
    margin-top: 5px;
  }
  
  .landing-page .main-heading {
    font-size: 2.5rem;
  }
  
  .landing-page .description {
    font-size: 16px;
  }
  
  .landing-page .input-section {
    flex-direction: column;
  }
  
  .landing-page .features-columns {
    flex-direction: column;
}

.landing-page .testimonials-section {
    padding: 40px 0;
    overflow: hidden;
}

.landing-page .testimonials-grid {
    grid-template-columns: 1fr;
    padding: 0;
    gap: 20px;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
}

.landing-page .testimonial-card {
    padding: 25px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
  
  .landing-page .product-image {
    max-width: 100%;
    height: auto;
  }
  
  .landing-page .footer-section {
    flex: 0 0 100%;
  }
  
  .landing-page .name-row {
    flex-direction: column;
    gap: 0;
  }
  
  .landing-page .section-header {
    padding: 0 5% 2%;
  }
  
  .landing-page .feature-item {
    padding: 20px;
  }
  
  .landing-page .feature-icon {
  margin-right: 15px;
    width: 50px;
    height: 50px;
  }
  
  .landing-page .feature-text h3 {
    font-size: 16px;
  }
  
  .landing-page .feature-text p {
  font-size: 14px;
  }
  
  .landing-page #testimonials {
    padding: 0 5% 2%;
    overflow: hidden;
  }
}

@media (max-width: 576px) {
  .landing-page .main-heading {
    font-size: 2rem;
  }
  
  .landing-page .tagline {
    font-size: 12px;
  }
  
  .landing-page .cta-button {
    width: 100%;
    padding: 14px 20px;
  font-size: 16px;
  }
  
  .landing-page .feature-selection .section-header h2,
  .landing-page .features-grid-section .feature-selection h2 {
    font-size: 2rem;
  }
  
  .landing-page .feature-item,
  .landing-page .benefit-item {
    padding: 16px;
  }
  
  .landing-page .feature-icon,
  .landing-page .benefit-icon {
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
  }
  
  .landing-page .nav {
    gap: 10px;
    justify-content: flex-start;
  }
  
  .landing-page .nav-item {
    font-size: 13px;
    margin-right: 10px;
    margin-bottom: 5px;
  }
  
  .landing-page .sign-up-button {
    padding: 8px 12px;
    font-size: 13px;
    margin-top: 5px;
    margin-left: 0;
  }
  
  .landing-page .testimonial-card {
    padding: 20px;
    width: 100%;
    margin: 0;
    max-width: 100%;
  }
  
  .landing-page .testimonial-quote {
    font-size: 14px;
    padding-left: 20px;
    max-width: 100%;
  }
  
  .landing-page .testimonial-quote:before {
    font-size: 30px;
  }
  
  .landing-page .section-header h2 {
    font-size: 1.8rem;
  }
  
  .landing-page .tag,
  .landing-page .tag-benefits {
    font-size: 12px;
    padding: 6px 12px;
}

.landing-page .footer {
    padding: 60px 5% 20px;
}

.landing-page .footer-content {
    gap: 30px;
  }
  
  .landing-page .header.scrolled {
    height: auto;
  }
  
  .landing-page .logo {
    font-size: 20px;
  }
  
  .landing-page .logo-image {
    width: 40px;
    height: 40px;
  }
  
  .landing-page .feature-container {
    gap: 20px;
  }
  
  .landing-page .cta-container {
    padding: 20px;
  }
  
  .landing-page .email-input {
    padding: 12px 14px;
    font-size: 14px;
  }
  
  .landing-page .email-label {
    font-size: 13px;
  }
  
  .landing-page #testimonials {
    padding: 0 4% 2%;
  }
  
  .landing-page .testimonials-section {
    padding: 30px 0;
  }
}

@media (max-width: 400px) {
  .landing-page .header {
    padding: 12px 4%;
  }
  
  .landing-page .nav {
  flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    margin-top: 12px;
  }
  
  .landing-page .nav-item {
    margin-bottom: 0;
  }
  
  .landing-page .sign-up-button {
    width: 100%;
    margin-top: 8px;
    text-align: center;
  }
  
  .landing-page .main-heading {
    font-size: 1.8rem;
  }
  
  .landing-page .description {
    font-size: 14px;
  }
  
  .landing-page .feature-selection,
  .landing-page .features-grid-section {
    padding: 40px 4%;
  }
  
  .landing-page .testimonial-card {
    padding: 15px;
    max-width: 100%;
  }
  
  .landing-page .feature-item {
    padding: 15px;
  }
  
  .landing-page .feature-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
    font-size: 1.2rem;
  }
  
  .landing-page .feature-text h3 {
    font-size: 15px;
  }
  
  .landing-page .feature-text p {
    font-size: 13px;
  }
  
  .landing-page .benefit-item {
    padding: 15px;
    min-height: auto;
  }
  
  .landing-page .benefit-header {
    gap: 10px;
  }
  
  .landing-page .benefit-icon {
    width: 35px;
    height: 35px;
    font-size: 1.2rem;
  }
  
  .landing-page .benefit-header h3 {
    font-size: 15px;
  }
  
  .landing-page .benefit-item p {
    font-size: 13px;
  }
  
  .landing-page .testimonial-info h3 {
  font-size: 16px;
  }
  
  .landing-page .testimonial-company {
    font-size: 13px;
  }
  
  .landing-page .testimonial-rating {
    font-size: 16px;
  }
  
  .landing-page #testimonials {
    padding: 0 3% 2%;
  }
  
  .landing-page .testimonials-section {
    padding: 20px 0;
  }
}

/* Add animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.landing-page .feature-column:nth-child(1) .benefit-item:nth-child(1) { animation-delay: 0.1s; }
.landing-page .feature-column:nth-child(1) .benefit-item:nth-child(2) { animation-delay: 0.2s; }
.landing-page .feature-column:nth-child(1) .benefit-item:nth-child(3) { animation-delay: 0.3s; }
.landing-page .feature-column:nth-child(2) .benefit-item:nth-child(1) { animation-delay: 0.2s; }
.landing-page .feature-column:nth-child(2) .benefit-item:nth-child(2) { animation-delay: 0.3s; }
.landing-page .feature-column:nth-child(2) .benefit-item:nth-child(3) { animation-delay: 0.4s; }
.landing-page .feature-column:nth-child(3) .benefit-item:nth-child(1) { animation-delay: 0.3s; }
.landing-page .feature-column:nth-child(3) .benefit-item:nth-child(2) { animation-delay: 0.4s; }
.landing-page .feature-column:nth-child(3) .benefit-item:nth-child(3) { animation-delay: 0.5s; }

.landing-page .testimonial-card:nth-child(1) { animation-delay: 0.1s; }
.landing-page .testimonial-card:nth-child(2) { animation-delay: 0.3s; }
.landing-page .testimonial-card:nth-child(3) { animation-delay: 0.5s; }

/* Enhanced Hero Section */
.landing-page .hero-buttons {
  display: flex;
  gap: 16px;
  margin-top: 24px;
  margin-bottom: 32px;
}

.landing-page .cta-button.primary {
  background: var(--primary-gradient);
  color: white;
}

.landing-page .cta-button.secondary {
  background: transparent;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
}

.landing-page .cta-button.secondary:hover {
  background: rgba(var(--primary-color-rgb), 0.1);
  transform: translateY(-3px);
}

.landing-page .social-proof {
  margin-top: 32px;
}

.landing-page .social-proof p {
  font-size: 14px;
  color: var(--text-secondary);
  margin-bottom: 12px;
}

.landing-page .company-logos {
  display: flex;
  gap: 24px;
  align-items: center;
}

.landing-page .company-logos span {
  color: var(--text-secondary);
  font-weight: 600;
  opacity: 0.7;
  font-size: 16px;
}

.landing-page .hero-image-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-page .main-image {
  position: relative;
  z-index: 1;
  max-width: 90%;
}

.landing-page .floating-stat {
  position: absolute;
  background: white;
  border-radius: 12px;
  padding: 12px 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  display: flex;
    flex-direction: column;
  z-index: 2;
  animation: float 6s ease-in-out infinite;
}

.landing-page .stat-1 {
  top: 15%;
  left: 0;
  animation-delay: 0s;
}

.landing-page .stat-2 {
  bottom: 20%;
  right: 0;
  animation-delay: 1.5s;
}

.landing-page .stat-number {
  font-size: 24px;
  font-weight: 700;
  color: var(--primary-color);
}

.landing-page .stat-text {
  font-size: 14px;
  color: var(--text-secondary);
  max-width: 150px;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* ROI Section */
.landing-page .roi-section {
  padding: 80px 5%;
  background-color: var(--bg-light);
  position: relative;
  overflow: hidden;
}

.landing-page .section-description {
    text-align: center;
  max-width: 700px;
  margin: 0 auto 40px;
  color: var(--text-secondary);
  font-size: 18px;
  line-height: 1.6;
}

.landing-page .metrics-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 24px;
  margin: 40px 0;
}

.landing-page .metric-card {
  background: white;
  border-radius: 16px;
  padding: 32px 24px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
  text-align: center;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.landing-page .metric-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12);
}

.landing-page .metric-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background: var(--primary-gradient);
}

.landing-page .metric-value {
  font-size: 48px;
  font-weight: 800;
  color: var(--primary-color);
  margin-bottom: 8px;
  line-height: 1;
}

.landing-page .metric-label {
  font-size: 18px;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 16px;
}

.landing-page .metric-description {
  font-size: 15px;
  color: var(--text-secondary);
  line-height: 1.5;
}

.landing-page .roi-cta {
  text-align: center;
  margin-top: 48px;
}

.landing-page .roi-cta p {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
  color: var(--text-primary);
}

/* Responsive adjustments for ROI section */
@media (max-width: 768px) {
  .landing-page .roi-section {
    padding: 60px 5%;
  }
  
  .landing-page .metrics-container {
    grid-template-columns: 1fr;
    max-width: 400px;
    margin: 30px auto;
  }
  
  .landing-page .metric-value {
    font-size: 36px;
  }
  
  .landing-page .metric-label {
    font-size: 16px;
  }
  
  .landing-page .section-description {
    font-size: 16px;
  }
}

/* Early Access Section */
.landing-page .early-access-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  background-color: var(--bg-white);
  border-radius: var(--border-radius-xl);
  box-shadow: var(--shadow-lg);
  overflow: hidden;
  margin: 40px auto;
  max-width: 1200px;
}

.landing-page .early-access-content {
  display: flex;
  flex-direction: column;
  padding: 40px;
  background: var(--primary-gradient);
  color: white;
}

.landing-page .early-access-benefits {
  flex: 1;
}

.landing-page .early-access-benefits h3 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 24px;
}

.landing-page .benefits-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.landing-page .benefits-list li {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-size: 16px;
}

.landing-page .benefit-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  margin-right: 12px;
  font-weight: 700;
}

.landing-page .early-access-image {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.landing-page .early-access-image img {
  max-width: 80%;
  max-height: 200px;
  object-fit: contain;
}

.landing-page .early-access-form {
  padding: 40px;
  background-color: white;
}

.landing-page .form-header {
  margin-bottom: 24px;
}

.landing-page .form-header h3 {
  font-size: 24px;
  font-weight: 700;
  color: var(--text-primary);
  margin-bottom: 8px;
}

.landing-page .form-header p {
  color: var(--text-secondary);
  font-size: 16px;
}

.landing-page .form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-bottom: 16px;
}

.landing-page .form-group {
  margin-bottom: 16px;
}

.landing-page .form-label {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 8px;
}

.landing-page .form-input {
  width: fit-content;
  padding: 12px 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius-md);
  font-size: 16px;
  transition: all 0.2s ease;
}

.landing-page .form-input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(var(--primary-color-rgb), 0.2);
}

.landing-page .form-textarea {
  resize: vertical;
  min-height: 100px;
}

.landing-page .form-submit {
  width: 100%;
  margin-top: 8px;
  padding: 14px;
  font-size: 16px;
}

.landing-page .form-disclaimer {
  margin-top: 16px;
  font-size: 14px;
  color: var(--text-tertiary);
  text-align: center;
}

.landing-page .form-disclaimer a {
  color: var(--primary-color);
  text-decoration: none;
}

.landing-page .form-disclaimer a:hover {
  text-decoration: underline;
}

/* Responsive adjustments for early access section */
@media (max-width: 992px) {
  .landing-page .early-access-container {
    grid-template-columns: 1fr;
  }
  
  .landing-page .early-access-content {
    padding: 30px;
  }
  
  .landing-page .early-access-form {
    padding: 30px;
  }

}

@media (max-width: 576px) {
  .landing-page .form-row {
    grid-template-columns: 1fr;
  }
  
  .landing-page .early-access-image {
    display: none;
  }
}

